import PropTypes from 'prop-types'
import React, { Component } from 'react'

class PercentageSlider extends Component {
  handleInputChange = (event) => {
    const { value } = event.target

    const numericValue = value === '' ? '' : parseFloat(value)

    this.props.onChange(numericValue || 0)
  }

  render() {
    const { disabled, maxValue, value } = this.props

    return (
      <div>
        <input
          type="number"
          min="0"
          max={maxValue}
          step="0.1"
          value={value.toString()}
          onChange={this.handleInputChange}
          disabled={disabled}
          placeholder={0}
        />{' '}
        %
        <input
          type="range"
          min="0"
          max={maxValue}
          step="1"
          value={value || 0}
          onChange={this.handleInputChange}
          disabled={disabled}
        />
      </div>
    )
  }
}

PercentageSlider.propTypes = {
  disabled: PropTypes.bool,
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}

export default PercentageSlider
