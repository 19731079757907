import React, { Component } from 'react'
import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
  DialogButton,
} from '../Dialog'
import PropTypes from 'prop-types'
import PercentageSlider from '../PercentageSlider'
import SettingsList, { SettingsListItem } from '../SettingsList'

class DiscountAdjustmentDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      changed: false,
      discountAmount: Math.floor(
        parseFloat(this.props.discount.discountInclVatOerer) / 100
      ),
      discountPercentage: parseFloat(this.props.discount.discountPercentage),
      errorMessage: '',
      valid: true,
    }
  }

  handleSave = () => {
    this.setState({ submitting: true })
    this.props.onUpdateDiscount(
      this.props.discount.id,
      this.state.discountPercentage
    )
    this.setState({ submitting: false, changed: false })
    this.closeModal()
  }

  handleAmountChange = (event) => {
    let eventValue = event.target.value
    let discountAmount = Math.floor(parseFloat(eventValue)) || 0
    let discountPercentage = parseFloat(
      (
        (discountAmount / this.props.wizardBuilding.totalPriceInclVat) *
        100
      ).toFixed(2)
    )

    this.setState({ discountAmount, discountPercentage, changed: true }, () => {
      this.validateDiscountPercentage(discountPercentage)
    })
  }

  handleDiscountChange = (value) => {
    if (value === '') {
      this.setState({ discountPercentage: '', discountAmount: '' })
      return
    }

    let discountAmount = Math.floor(
      (value * this.props.wizardBuilding.totalPriceInclVat) / 100
    )
    this.setState(
      { discountAmount, discountPercentage: value, changed: true },
      () => {
        this.validateDiscountPercentage(value)
      }
    )
  }

  validateDiscountPercentage = (discountPercentage) => {
    let errorMessage = ''
    let valid = true

    if (
      discountPercentage >
      parseFloat(this.props.wizardBuilding.maxPossibleDiscount)
    ) {
      errorMessage = 'Rabatten er større end den maksimale rabat'
      valid = false
    } else if (discountPercentage < 0) {
      errorMessage = 'Rabatten kan ikke være negativ'
      valid = false
    }

    this.setState({ errorMessage, valid })
  }

  closeModal = () => {
    this.props.closeModal()
  }

  render() {
    const { changed, errorMessage, submitting, valid } = this.state
    const { wizardBuilding } = this.props
    const {
      humanizedDefaultDiscountInclVatWithSymbol,
      humanizedMaxPossibleDiscountInclVatWithSymbol,
      wizard,
    } = wizardBuilding

    const wizardName = wizard.name
    const defaultDiscount = parseFloat(wizard.defaultDiscount)
    const maxPossibleDiscount = parseFloat(wizardBuilding.maxPossibleDiscount)
    const { humanizedWalletBalance } = this.props.user
    const dialogTitle = `Tilpasning af ${wizardName}rabat`

    return (
      <Dialog className="z-index-1000">
        <DialogHeader>
          <DialogCancelButton onClick={this.closeModal}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogButton
            onClick={this.handleSave}
            disabled={submitting || !changed || !valid}
          >
            Gem
          </DialogButton>
        </DialogHeader>
        <SettingsList>
          <SettingsListItem
            narrow
            label="Afgivet rabat (inkl. moms):"
            error={errorMessage}
          >
            <input
              type="text"
              value={this.state.discountAmount}
              onChange={this.handleAmountChange}
              placeholder="0.00"
            />{' '}
            kr.
            <PercentageSlider
              onChange={this.handleDiscountChange}
              maxValue={maxPossibleDiscount}
              value={this.state.discountPercentage}
            />
          </SettingsListItem>
        </SettingsList>
        <SettingsList>
          <SettingsListItem narrow label="Rabatkonto saldo:">
            {humanizedWalletBalance}
          </SettingsListItem>
        </SettingsList>
        <SettingsList>
          <SettingsListItem narrow label="Standardrabat:">
            {humanizedDefaultDiscountInclVatWithSymbol} ({defaultDiscount}%)
          </SettingsListItem>
        </SettingsList>
        <SettingsList>
          <SettingsListItem narrow label="Maksimalrabat:">
            {humanizedMaxPossibleDiscountInclVatWithSymbol} (
            {maxPossibleDiscount}%)
          </SettingsListItem>
        </SettingsList>
      </Dialog>
    )
  }
}

DiscountAdjustmentDialog.propTypes = {
  onUpdateDiscount: PropTypes.func.isRequired,
  user: PropTypes.shape({
    humanizedWalletBalance: PropTypes.string.isRequired,
  }).isRequired,
  wizardBuilding: PropTypes.shape({
    humanizedDefaultDiscountInclVatWithSymbol: PropTypes.string.isRequired,
    humanizedMaxPossibleDiscountInclVatWithSymbol: PropTypes.string.isRequired,
    maxPossibleDiscount: PropTypes.string.isRequired,
    wizard: PropTypes.shape({
      defaultDiscount: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    totalPriceInclVatOerer: PropTypes.number.isRequired,
  }).isRequired,
  discount: PropTypes.shape({
    id: PropTypes.string.isRequired,
    discountPercentage: PropTypes.number.isRequired,
    discountInclVatOerer: PropTypes.number.isRequired,
  }).isRequired,
}

export default DiscountAdjustmentDialog
