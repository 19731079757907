import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './style.styl'
import IconButton from '../Button/IconButton'

class WizardBuildingDiscountRow extends Component {
  render() {
    const { allowLineItemEditing, openModal } = this.props
    const { discountPercentage, humanizedDiscountInclVatWithSymbol } =
      this.props.discount

    return (
      <tr>
        <th className="WizardBuildingStepTable__description" colSpan="3">
          Pakkerabat
        </th>
        <td>{discountPercentage}%</td>
        <td className="WizardBuildingDiscountRow__discount">
          {humanizedDiscountInclVatWithSymbol}
        </td>
        {allowLineItemEditing && (
          <td>
            <IconButton
              icon="pencil"
              onClick={() => openModal(this.props.discount)}
            />
          </td>
        )}
      </tr>
    )
  }
}

WizardBuildingDiscountRow.propTypes = {
  allowLineItemEditing: PropTypes.bool,
  onUpdateDiscount: PropTypes.func,
  user: PropTypes.shape({
    humanizedWalletBalance: PropTypes.string.isRequired,
  }).isRequired,
  wizardBuilding: PropTypes.shape({
    humanizedMaxPossibleDiscountInclVatWithSymbol: PropTypes.string.isRequired,
    maxPossibleDiscount: PropTypes.string.isRequired,
    wizard: PropTypes.shape({
      name: PropTypes.string.isRequired,
      maxDiscount: PropTypes.string.isRequired,
    }).isRequired,
    totalPriceInclVatOerer: PropTypes.number.isRequired,
  }).isRequired,
  discount: PropTypes.shape({
    id: PropTypes.string.isRequired,
    discountInclVatOerer: PropTypes.number.isRequired,
    discountPercentage: PropTypes.number.isRequired,
    humanizedDiscountInclVatWithSymbol: PropTypes.string.isRequired,
  }).isRequired,
}

export default WizardBuildingDiscountRow
