import { hashToFormData } from '../utils/formUtils'
import { deleteEntity, updateEntity } from './ApiActions'

const attributesToFormData = (attributes) =>
  hashToFormData({
    'discount[name]': attributes.name,
  })

export const updateDiscount = (id, attributes) =>
  updateEntity(
    { type: 'discounts', id },
    { body: attributesToFormData(attributes) }
  )

export const updateDiscountPercentage = (id, attributes) =>
  updateEntity(
    { type: 'discounts', id },
    {
      body: hashToFormData({
        'discount[discount_percentage]': attributes.discountPercentage,
      }),
    }
  )

export const deleteDiscount = (id) => deleteEntity({ type: 'discounts', id })
