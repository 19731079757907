import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import * as OrderActions from '../../actions/OrderActions'
import * as CommentActions from '../../actions/CommentActions'
import * as ActivityActions from '../../actions/ActivityActions'

import { getCurrentUser } from '../../selectors/AuthSelectors'
import { fetchUsers } from '../../actions/UserActions'
import * as UserSelectors from '../../selectors/UserSelectors'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogButton,
  DialogCancelButton,
} from '../../components/Dialog'
import { Margin } from '../../components/Grid'
import OrderReviewAcceptForm from '../../components/OrderReviewAcceptForm'
import RadioGroup from '../../components/RadioGroup'
import TextArea from '../../components/TextArea'

import { isValidEmail } from '../../utils/validationUtils'
import filterDuplicates from '../../utils/filterDuplicates'

const reviewTypes = [
  { label: 'Acceptér', value: 'accept' },
  { label: 'Afvis', value: 'reject' },
]

const defaultBodyTemplate = ({ customerName, userName }) =>
  [
    `Hej ${customerName}`,
    'Tak for din ordre.',
    `På vegne af ${userName} sender jeg dig hermed din ordrebekræftelse.`,
    'Det er desværre ikke muligt for os, at give specifikke opstartsdatoer, da meget af vores arbejde bl.a. er afhængigt af vejret.',
    'Du vil blive kontaktet af os før opstart, så vi sammen kan aftale de nærmere detaljer.',
    'Hvis du skulle have spørgsmål til yderligere, så skal du være velkommen til at kontakte mig.',
    'Vi ser meget frem til at stå for din opgave i samarbejde med vores dygtige håndværkere.',
  ].join('\n\n')

class OrderReviewDialog extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    isFetchingUsers: PropTypes.bool.isRequired,
    rejectOrderReview: PropTypes.func.isRequired,
    acceptOrderReview: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    order: PropTypes.shape({
      id: PropTypes.string.isRequired,
      expectedStartUpTime: PropTypes.string,
      customer: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      termsOfPayment: PropTypes.string.isRequired,
      introductionText: PropTypes.string.isRequired,
      practicalInformation: PropTypes.string.isRequired,
      wizardBuildings: PropTypes.arrayOf(
        PropTypes.shape({
          wizard: PropTypes.object.isRequired,
          wizardBuildingSteps: PropTypes.arrayOf(
            PropTypes.shape({
              step: PropTypes.shape({
                stepProducts: PropTypes.arrayOf(
                  PropTypes.shape({
                    productVariant: PropTypes.shape({
                      practicalInformation: PropTypes.string,
                    }),
                    product: PropTypes.shape({
                      practicalInformation: PropTypes.string,
                    }),
                  })
                ),
              }),
            })
          ),
        })
      ).isRequired,
    }).isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      })
    ).isRequired,
  }

  constructor(props) {
    super(props)

    const {
      order,
      order: { customer, user },
    } = props

    const wizards = order.wizardBuildings.map((wb) => wb.wizard)

    // Only override if the order's existing `introductionText` is empty
    let introductionText = order.introductionText || ''
    if (!introductionText) {
      introductionText =
        'Jeg har fornøjelsen af at præsentere følgende tilbud for dig.'
    }

    const wizardBuildingPraticalInformation = (wizardBuilding) => {
      const practicalInformations = [
        wizardBuilding.wizard.practicalInformation.trim(),
      ].concat(
        wizardBuilding.wizardBuildingSteps.flatMap((wizardBuildingStep) =>
          wizardBuildingStep.step.stepProducts.map((stepProduct) =>
            (
              stepProduct.productVariant?.practicalInformation ||
              stepProduct.product?.practicalInformation ||
              ''
            ).trim()
          )
        )
      )
      return practicalInformations
        .filter((text) => text !== '')
        .filter(filterDuplicates)
        .join('\n')
    }

    // Only override if the order's existing `practicalInformation` is empty
    let practicalInformation = order.practicalInformation || ''
    if (!practicalInformation) {
      practicalInformation = order.wizardBuildings
        .map((wizardBuilding) =>
          wizardBuildingPraticalInformation(wizardBuilding)
        )
        .filter((text) => text !== '')
        .filter(filterDuplicates)
        .join('\n\n')
    }

    // Only override if the order's existing `termsOfPayment` is empty
    let termsOfPayment = order.termsOfPayment || ''
    if (!termsOfPayment) {
      termsOfPayment = wizards
        .map((wizard) => wizard.termsOfPayment.trim())
        .filter((text) => text !== '')
        .filter(filterDuplicates)
        .join('\n\n')
    }

    this.state = {
      reviewType: 'accept',
      submitting: false,
      fields: {
        accept: {
          startDate: order.expectedStartUpTime || '',
          termsOfPayment,
          introductionText,
          practicalInformation,
          note: order.note || '',
          email: customer.email || '',
          body: defaultBodyTemplate({
            customerName: customer.name,
            userName: user.name,
          }),
        },
        reject: {
          comment: '',
        },
      },
    }

    this.handleReviewTypeChange = this.handleReviewTypeChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
  }

  componentDidMount() {
    this.props.fetchUsers()
  }

  getUsersSelectOptions() {
    const { users, isFetchingUsers } = this.props

    if (isFetchingUsers) {
      return [{ value: '', label: 'Indlæser brugere...' }]
    }

    const userOptions = users.map((user) => ({
      value: user.email,
      label: `${user.name} (${user.email})`,
    }))

    return [{ value: '', label: 'Vælg afsender' }].concat(userOptions)
  }

  validate() {
    const { reviewType } = this.state

    if (reviewType === 'accept') {
      const { fields } = this.state
      const { startDate, email, body } = fields.accept

      return (
        startDate.trim() !== '' && body.trim() !== '' && isValidEmail(email)
      )
    } else if (reviewType === 'reject') {
      const { fields } = this.state
      const { comment } = fields.reject

      return comment.trim() !== ''
    }

    return false
  }

  handleReviewTypeChange(reviewType) {
    this.setState({ reviewType })
  }

  handleInputChange({ target }) {
    const { reviewType, fields } = this.state
    const { name, value } = target

    this.setState({
      fields: {
        ...fields,
        [reviewType]: {
          ...fields[reviewType],
          [name]: value,
        },
      },
    })
  }

  handleOrderAcceptance() {
    const { updateOrder, acceptOrderReview, order } = this.props
    const {
      replyTo,
      startDate,
      termsOfPayment,
      introductionText,
      practicalInformation,
      note,
      body,
      email,
    } = this.state.fields.accept

    return updateOrder(order.id, {
      expected_start_up_time: startDate,
      terms_of_payment: termsOfPayment,
      introduction_text: introductionText,
      practical_information: practicalInformation,
      note: note,
    }).then(() => acceptOrderReview(order.id, { replyTo, body, email }))
  }

  handleOrderRejection() {
    const { rejectOrderReview, createComment, order } = this.props
    const { comment } = this.state.fields.reject

    return rejectOrderReview(order.id).then(() =>
      createComment({
        commentableId: order.id,
        commentableType: 'orders',
        content: comment,
        meta: { type: 'review-rejected' },
      })
    )
  }

  handleSubmitClick() {
    const { reviewType } = this.state
    const { onClose, fetchActivitiesForTrackable, order } = this.props
    const promise =
      reviewType === 'accept'
        ? this.handleOrderAcceptance()
        : this.handleOrderRejection()

    this.setState({ submitting: true })

    return promise
      .then(() =>
        fetchActivitiesForTrackable({
          trackableTypes: ['orders'],
          trackableId: order.id,
        })
      )
      .then(onClose)
      .catch(() => this.setState({ submitting: false }))
  }

  renderAcceptForm() {
    const { order } = this.props
    const {
      submitting,
      fields: { accept: values },
    } = this.state

    return (
      <OrderReviewAcceptForm
        userOptions={this.getUsersSelectOptions()}
        submitting={submitting}
        values={values}
        order={order}
        onChange={this.handleInputChange}
      />
    )
  }

  renderRejectForm() {
    const { submitting, fields } = this.state
    const attrs = fields.reject

    return (
      <Margin all>
        <TextArea
          name="comment"
          value={attrs.comment}
          disabled={submitting}
          placeholder="Hvorfor skal dette tilbud afvises?"
          onChange={this.handleInputChange}
        />
      </Margin>
    )
  }

  render() {
    const { reviewType, submitting } = this.state
    const { onClose } = this.props
    const disabled = submitting || !this.validate()

    return (
      <Dialog style={{ width: '500px' }}>
        <DialogHeader>
          <DialogCancelButton onClick={onClose} disabled={submitting}>
            Annuller
          </DialogCancelButton>
          <DialogTitle>Gennemse tilbud</DialogTitle>
          <DialogButton disabled={disabled} onClick={this.handleSubmitClick}>
            {reviewType === 'accept' ? 'Acceptér tilbud' : 'Afvis tilbud'}
          </DialogButton>
        </DialogHeader>
        <Margin all>
          <RadioGroup
            options={reviewTypes}
            value={reviewType}
            onChange={this.handleReviewTypeChange}
          />
        </Margin>
        {reviewType === 'accept'
          ? this.renderAcceptForm()
          : this.renderRejectForm()}
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => ({
  isFetchingUsers: UserSelectors.isFetching(state),
  currentUser: getCurrentUser(state),
  users: UserSelectors.getReviewersForOrder(state, props.order),
})

export const UnconnectedOrderReviewDialog = OrderReviewDialog

export default connect(mapStateToProps, {
  ...OrderActions,
  ...CommentActions,
  ...ActivityActions,
  fetchUsers,
})(OrderReviewDialog)
