import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import Input from '../Input'
import SettingsList, { SettingsListItem } from '../SettingsList'
import { Row, Column } from '../Grid'

class CustomerForm extends Component {
  static fields = [
    'name',
    'email',
    'phone',
    'addressStreetName',
    'addressStreetNumber',
    'addressAdditionalInfo',
    'addressZipCode',
    'addressCityName',
  ]

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
  }

  submit() {
    this.buttonElement.dispatchEvent(new MouseEvent('click'))
  }

  render() {
    const {
      fields: {
        name,
        email,
        phone,
        addressStreetName,
        addressStreetNumber,
        addressAdditionalInfo,
        addressZipCode,
        addressCityName,
      },
      submitting,
      dirty,
      onSubmit,
    } = this.props
    const disabled = submitting

    return (
      <form onSubmit={onSubmit}>
        <SettingsList>
          <SettingsListItem label="Navn">
            <Input type="text" disabled={submitting} {...name} />
          </SettingsListItem>
          <SettingsListItem label="E-mail">
            <Input type="email" disabled={submitting} {...email} />
          </SettingsListItem>
          <SettingsListItem label="Tlf. nr.">
            <Input
              type="text"
              placeholder="12345678 eller +4512345678"
              disabled={submitting}
              {...phone}
            />
          </SettingsListItem>
          <SettingsListItem label="Adresse">
            <Row>
              <Column size={8}>
                <Input
                  type="text"
                  placeholder="Gadenavn"
                  disabled={submitting}
                  {...addressStreetName}
                />
              </Column>
              <Column size={4}>
                <Input
                  type="text"
                  placeholder="Gadenr."
                  disabled={submitting}
                  {...addressStreetNumber}
                />
              </Column>
            </Row>
            <br />
            <Input
              type="text"
              placeholder="Lejlighed, suite, enhed etc"
              disabled={submitting}
              {...addressAdditionalInfo}
            />
          </SettingsListItem>
          <SettingsListItem label="Postnr. og by">
            <Row>
              <Column size={4}>
                <Input type="text" disabled={submitting} {...addressZipCode} />
              </Column>
              <Column size={8}>
                <Input type="text" disabled={submitting} {...addressCityName} />
              </Column>
            </Row>
          </SettingsListItem>
        </SettingsList>
        <button
          ref={(element) => (this.buttonElement = element)}
          type="submit"
          style={{ display: 'none' }}
          disabled={!dirty || disabled}
        />
      </form>
    )
  }
}

export default CustomerForm
