import React, { Component } from 'react'
import PropTypes from 'react-proptypes'

class OrderWizardDiscountRow extends Component {
  render() {
    const { discounts, humanizedDiscountInclVatWithSymbol } = this.props.order
    const discountName = discounts[0]?.name || ''

    return (
      <tr key="aggregated-discounts">
        <td>Pakkerabatter</td>
        <td>{discountName}</td>
        <td colSpan={2}>{humanizedDiscountInclVatWithSymbol}</td>
      </tr>
    )
  }
}

OrderWizardDiscountRow.propTypes = {
  order: PropTypes.shape({
    humanizedDiscountInclVatWithSymbol: PropTypes.string.isRequired,
    discounts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }).isRequired,
}

export default OrderWizardDiscountRow
