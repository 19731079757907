import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { fetchActivitiesForTrackable } from '../actions/ActivityActions'
import { openDialog } from '../actions/DialogActions'
import * as OrderActions from '../actions/OrderActions'
import { IconButton } from '../components/Button'
import { Flex, FlexContainer, Margin } from '../components/Grid'
import OrderDiscountsTable from '../components/OrderDiscountsTable'
import OrderPageActions from '../components/OrderPage/Actions'
import OrderPageHeader from '../components/OrderPage/Header'
import OrderPriceTable from '../components/OrderPriceTable'
import OrderTree from '../components/OrderTree'
import Page, { PageHeader } from '../components/Page'
import Pre from '../components/Pre'
import Spinner from '../components/Spinner'
import WizardBuildingTable from '../components/WizardBuildingTable'
import { orderProgressSameOrAfter } from '../constants/OrderStates'
import * as AuthSelectors from '../selectors/AuthSelectors'
import * as OrderSelectors from '../selectors/OrderSelectors'
import {
  canRoleDisableReminders,
  canRoleManageOrder,
  canRoleResetOrder,
} from '../utils/abilityUtils'
import ActivitiesContainer from './ActivitiesContainer'

const orderRelations = [
  'user',
  'discounts.coupon',
  'wizardBuildings.building',
  'wizardBuildings.discounts',
  'wizardBuildings.wizard',
  'wizardBuildings.wizardBuildingSteps.lineItems.buildingProperty',
  'wizardBuildings.wizardBuildingSteps.step.stepProducts.product',
  'wizardBuildings.wizardBuildingSteps.step.stepProducts.productVariant',
  'customer',
]

class OrderContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    fetchOrderWithRelations: PropTypes.func.isRequired,
    getOrderPDFLink: PropTypes.func.isRequired,
    getOrderConditionsPDFLink: PropTypes.func.isRequired,
    getOrderGuaranteesPDFLink: PropTypes.func.isRequired,
    getOrderConfirmationPDFLink: PropTypes.func.isRequired,
    getOrderWorklistPDFLink: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
    exportOrderToAx: PropTypes.func.isRequired,
    rejectOrderReview: PropTypes.func.isRequired,
    fetchActivitiesForTrackable: PropTypes.func.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    copyOrder: PropTypes.func.isRequired,
    archiveOrder: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,
    disableOrderReminders: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    role: PropTypes.string,
    order: PropTypes.object,
    params: PropTypes.shape({
      orderId: PropTypes.string.isRequired,
    }).isRequired,
    canCopyOrder: PropTypes.bool.isRequired,
    canArchiveOrder: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isCopying: false,
    }

    this.handleOpenPDFClick = this.handleOpenPDFClick.bind(this)
    this.handleOpenConditionsPDFClick =
      this.handleOpenConditionsPDFClick.bind(this)
    this.handleOpenGuaranteesPDFClick =
      this.handleOpenGuaranteesPDFClick.bind(this)
    this.handleOpenConfirmationPDFClick =
      this.handleOpenConfirmationPDFClick.bind(this)
    this.handleResendConfirmationClick =
      this.handleResendConfirmationClick.bind(this)
    this.handleOpenOrderWorklistPDFClick =
      this.handleOpenOrderWorklistPDFClick.bind(this)
    this.handlePrintClick = this.handlePrintClick.bind(this)
    this.handlePrintWorksheetClick = this.handlePrintWorksheetClick.bind(this)
    this.handleRejectClick = this.handleRejectClick.bind(this)
    this.handleApproveClick = this.handleApproveClick.bind(this)
    this.handleFollowUpClick = this.handleFollowUpClick.bind(this)
    this.handleNewCommentClick = this.handleNewCommentClick.bind(this)
    this.handleRateTrustpilotClick = this.handleRateTrustpilotClick.bind(this)
    this.handleExporToAxClick = this.handleExporToAxClick.bind(this)
    this.handleReviewClick = this.handleReviewClick.bind(this)
    this.handleCustomerEditClick = this.handleCustomerEditClick.bind(this)
    this.handleCopyClick = this.handleCopyClick.bind(this)
    this.handleArchiveClick = this.handleArchiveClick.bind(this)
    this.handlePrintDetailsClick = this.handlePrintDetailsClick.bind(this)
    this.handleShowSignatureClick = this.handleShowSignatureClick.bind(this)
    this.handleResetOrderClick = this.handleResetOrderClick.bind(this)
    this.handleProposeOrderClick = this.handleProposeOrderClick.bind(this)
    this.handleMergeOrderClick = this.handleMergeOrderClick.bind(this)
    this.handleManagerFollowUpClick = this.handleManagerFollowUpClick.bind(this)
    this.handleCancelOrderClick = this.handleCancelOrderClick.bind(this)
    this.handleDisableRemindersClick =
      this.handleDisableRemindersClick.bind(this)

    this.handleEditIntroductionTextClick = this.handleEditFieldClick.bind(
      this,
      'introductionText',
      'Redigér introduktion'
    )
    this.handleEditTermsOfPaymentClick = this.handleEditFieldClick.bind(
      this,
      'termsOfPayment',
      'Redigér betalingsbetingelser'
    )
    this.handleEditPracticalInformationClick = this.handleEditFieldClick.bind(
      this,
      'practicalInformation',
      'Redigér praktiske informationer'
    )
    this.handleEditNoteClick = this.handleEditFieldClick.bind(
      this,
      'note',
      'Redigér bemærkninger'
    )
  }

  componentDidMount() {
    const {
      fetchOrder,
      params: { orderId },
    } = this.props
    fetchOrder(orderId, {
      query: {
        include: orderRelations,
      },
    })
  }

  componentWillReceiveProps(nextProps) {
    const { fetchOrderWithRelations, params } = this.props
    const nextId = nextProps.params.orderId
    if (nextId !== params.orderId) {
      fetchOrderWithRelations(nextId)
    }
  }

  openOrderPDF(withLogo) {
    const { getOrderPDFLink, order } = this.props
    window.open(getOrderPDFLink(order, withLogo))
  }

  openOrderConditionsPDF() {
    const { getOrderConditionsPDFLink, order } = this.props
    window.open(getOrderConditionsPDFLink(order))
  }

  openOrderGuaranteesPDF() {
    const { getOrderGuaranteesPDFLink, order } = this.props
    window.open(getOrderGuaranteesPDFLink(order))
  }

  openOrderConfirmationPDF() {
    const { getOrderConfirmationPDFLink, order } = this.props
    window.open(getOrderConfirmationPDFLink(order))
  }

  openOrderWorklistPDF() {
    const { getOrderWorklistPDFLink, order } = this.props
    window.open(getOrderWorklistPDFLink(order))
  }

  handleOpenPDFClick(withLogo) {
    this.openOrderPDF(withLogo)
  }

  handleOpenConditionsPDFClick() {
    this.openOrderConditionsPDF()
  }

  handleOpenGuaranteesPDFClick() {
    this.openOrderGuaranteesPDF()
  }

  handleOpenConfirmationPDFClick() {
    this.openOrderConfirmationPDF()
  }

  handleResendConfirmationClick() {
    const { order } = this.props
    const { id, customer } = order

    this.props.openDialog('sendOrderConfirmation', {
      email: customer && customer.email,
      orderId: id,
    })
  }

  handleOpenOrderWorklistPDFClick() {
    this.openOrderWorklistPDF()
  }

  handlePrintClick() {
    this.openOrderPDF()
  }

  handlePrintWorksheetClick() {
    const { order } = this.props
    const params = { orderId: order.id }
    this.props.openDialog('printWorksheet', { params })
  }

  handleApproveClick() {
    this.props.openDialog('orderAccept', { params: { ...this.props.params } })
  }

  handleRejectClick() {
    this.props.openDialog('orderReject', { params: { ...this.props.params } })
  }

  handleArchiveClick() {
    if (confirm('Er du sikker på at du vil arkivere denne ordrevariant?')) {
      this.props.archiveOrder(this.props.order.id)
    }
  }

  handleFollowUpClick() {
    this.props.openDialog('orderFollowUp', { params: { ...this.props.params } })
  }

  handleNewCommentClick() {
    this.props.openDialog('orderNewComment', {
      params: { ...this.props.params },
    })
  }

  handleRateTrustpilotClick() {
    this.props.openDialog('orderRateTrustpilot', {
      orderId: this.props.order.id,
      type: 'order',
      user: this.props.order.user,
      customer: this.props.order.customer,
    })
  }

  handleReviewClick() {
    const { order } = this.props
    this.props.openDialog('orderReview', { order })
  }

  handleManagerFollowUpClick() {
    const { order } = this.props
    this.props.openDialog('managerOrderFollowUp', {
      orderId: order.id,
    })
  }

  handleCancelOrderClick() {
    this.props.openDialog('orderCancel', { params: { ...this.props.params } })
  }

  handleCustomerEditClick(customer) {
    this.props.openDialog('customer', { customer })
  }

  handleCopyClick() {
    const { order, copyOrder } = this.props
    const onDone = () => this.setState({ isCopying: false })

    this.setState({ isCopying: true })

    copyOrder(order.id)
      .then((response) => {
        const copiedOrderId = response.results.orders[0]

        onDone()
        this.props.push(`/drafts/${copiedOrderId}`)
      })
      .then(onDone)
  }

  handleResetOrderClick() {
    const {
      order: { id, state },
      resetOrderAcceptance,
      resetOrderReview,
      resetOrderFromAX,
      resetOrderCancel,
    } = this.props

    if (!confirm('Er du sikker på at du vil nulstille denne tilbudsstatus?')) {
      return false
    }

    if (state === 'accepted' || state === 'rejected') {
      resetOrderAcceptance(id)
    } else if (state === 'reviewed_accepted' || state === 'reviewed_rejected') {
      resetOrderReview(id)
    } else if (state === 'exported_to_ax') {
      resetOrderFromAX(id)
    } else if (state === 'cancelled') {
      resetOrderCancel(id)
    }
  }

  hasNeededCustomerAndUser(order) {
    return order != null && order.customer != null && order.user != null
  }

  handleExporToAxClick() {
    const { order } = this.props
    this.props.exportOrderToAx(order.id).then(() =>
      this.props.fetchActivitiesForTrackable({
        trackableTypes: ['orders'],
        trackableId: order.id,
      })
    )
  }

  handlePrintDetailsClick() {
    window.print()
  }

  handleShowSignatureClick() {
    this.props.openDialog('acceptance', { order: this.props.order })
  }

  handleProposeOrderClick() {
    const { order } = this.props
    this.props.proposeOrder(order.id)
  }

  handleMergeOrderClick() {
    const { order } = this.props
    this.props.push(`/orders/${order.id}/merge`)
  }

  handleEditFieldClick(field, title) {
    const { order } = this.props
    this.props.openDialog('orderEditField', {
      orderId: order.id,
      title: title,
      field: field,
    })
  }

  handleDisableRemindersClick() {
    const { order } = this.props

    this.props.disableOrderReminders(order.id)
  }

  render() {
    const { isCopying } = this.state
    const {
      isFetching,
      role,
      order,
      canCopyOrder,
      canArchiveOrder,
      updateOrder,
    } = this.props
    const canManageOrder = canRoleManageOrder(role)

    if (isFetching || !this.hasNeededCustomerAndUser(order)) {
      return <Spinner center />
    }
    const canShowSignature = !!(
      order.customerSignatureUrl ||
      order.acceptanceDocumentUrl ||
      order.signedWithIpAddress
    )

    const canResetOrder = canRoleResetOrder(role, order)

    const canDisableReminders = canRoleDisableReminders(role, order)

    const showEditableOrderFields = orderProgressSameOrAfter(
      order.state,
      'reviewed_accepted'
    )

    return (
      <Page>
        <PageHeader title={`Tilbud #${order.id}`} />
        <FlexContainer horizontal>
          <Flex size={9} scrollY>
            <Margin horizontal>
              <Margin vertical>
                <OrderPageHeader
                  order={order}
                  customer={order.customer}
                  salesman={order.user}
                  onCustomerEditClick={this.handleCustomerEditClick}
                />
              </Margin>

              {showEditableOrderFields && (
                <div>
                  <Margin vertical>
                    <strong>Introduktion (i PDF)</strong>
                    {canManageOrder && (
                      <IconButton
                        icon="compose"
                        onClick={this.handleEditIntroductionTextClick}
                      />
                    )}
                    <br />
                    <Pre>{order.introductionText || <i>Ikke sat</i>}</Pre>
                  </Margin>
                  <Margin vertical>
                    <strong>Betalingsbetingelser</strong>
                    {canManageOrder && (
                      <IconButton
                        icon="compose"
                        onClick={this.handleEditTermsOfPaymentClick}
                      />
                    )}
                    <br />
                    <Pre>{order.termsOfPayment || <i>Ikke sat</i>}</Pre>
                  </Margin>
                  <Margin vertical>
                    <strong>Praktiske informationer</strong>
                    {canManageOrder && (
                      <IconButton
                        icon="compose"
                        onClick={this.handleEditPracticalInformationClick}
                      />
                    )}
                    <br />
                    <Pre>{order.practicalInformation || <i>Ikke sat</i>}</Pre>
                  </Margin>
                  <Margin vertical>
                    <strong>Bemærkninger (i PDF)</strong>
                    {canManageOrder && (
                      <IconButton
                        icon="compose"
                        onClick={this.handleEditNoteClick}
                      />
                    )}
                    <br />
                    <Pre>{order.note || <i>Ikke sat</i>}</Pre>
                  </Margin>
                </div>
              )}

              <Margin vertical>
                Relaterede tilbud
                <OrderTree tree={order.familyTree} currentId={order.id} />
              </Margin>
              <Margin vertical>
                Tilbudsdetaljer
                {order.wizardBuildings.map((wizardBuilding) => (
                  <Margin key={wizardBuilding.id} bottom size={3}>
                    <WizardBuildingTable
                      wizardBuilding={wizardBuilding}
                      user={order.user}
                    />
                  </Margin>
                ))}
                <Margin bottom>
                  <OrderDiscountsTable order={order} />
                </Margin>
                <Margin vertical>
                  <OrderPriceTable order={order} />
                </Margin>
              </Margin>
            </Margin>
          </Flex>
          <Flex size={4} scrollY className="hide-print">
            <Margin all>
              <OrderPageActions
                canManageOrder={canManageOrder}
                canArchiveOrder={canArchiveOrder}
                canResetOrder={canResetOrder}
                isCopying={isCopying}
                canCopyOrder={canCopyOrder}
                canShowSignature={canShowSignature}
                canDisableReminders={canDisableReminders}
                canMergeOrder
                orderState={order.state}
                onReviewClick={this.handleReviewClick}
                onOpenPDFClick={this.handleOpenPDFClick}
                onOpenConditionsPDFClick={this.handleOpenConditionsPDFClick}
                onOpenGuaranteesPDFClick={this.handleOpenGuaranteesPDFClick}
                onOpenConfirmationPDFClick={this.handleOpenConfirmationPDFClick}
                onResendConfirmationClick={this.handleResendConfirmationClick}
                onOpenOrderWorklistPDFClick={
                  this.handleOpenOrderWorklistPDFClick
                }
                onPrintClick={this.handlePrintClick}
                onPrintWorksheetClick={this.handlePrintWorksheetClick}
                onRejectClick={this.handleRejectClick}
                onApproveClick={this.handleApproveClick}
                onFollowUpClick={this.handleFollowUpClick}
                onNewCommentClick={this.handleNewCommentClick}
                onRateTrustpilotClick={this.handleRateTrustpilotClick}
                onExportToAxClick={this.handleExporToAxClick}
                onCopyClick={this.handleCopyClick}
                onArchiveClick={this.handleArchiveClick}
                onPrintDetailsClick={this.handlePrintDetailsClick}
                onShowSignatureClick={this.handleShowSignatureClick}
                onResetOrderClick={this.handleResetOrderClick}
                onProposeOrderClick={this.handleProposeOrderClick}
                onMergeOrderClick={this.handleMergeOrderClick}
                onManagerFollowUpClick={this.handleManagerFollowUpClick}
                onCancelOrderClick={this.handleCancelOrderClick}
                onDisableRemindersClick={this.handleDisableRemindersClick}
                orderRemindersEnabled={order.remindersEnabled}
              />
              <Margin top>
                <p style={{ marginBottom: '-12px' }}>Hændelser</p>
                <ActivitiesContainer
                  trackableId={order.id}
                  trackableTypes={['orders']}
                />
              </Margin>
            </Margin>
          </Flex>
        </FlexContainer>
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFetching: OrderSelectors.isFetching(state),
  role: AuthSelectors.getCurrentUserRole(state),
  order: OrderSelectors.getOrder(state, ownProps, orderRelations),
  canCopyOrder: OrderSelectors.canCopyOrder(state, ownProps),
  canArchiveOrder: OrderSelectors.canArchiveOrder(state, ownProps),
})

export default connect(mapStateToProps, {
  ...OrderActions,
  fetchActivitiesForTrackable,
  openDialog,
  push,
})(OrderContainer)
