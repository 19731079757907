import React, { Component } from 'react'

class OrderDisplayedWizardDiscountRow extends Component {
  render() {
    const { order } = this.props

    const {
      discounts,
      displayedDiscountSet,
      humanizedDiscountInclVatWithSymbol,
      humanizedDisplayedDiscountWithSymbol,
    } = order

    const discount = displayedDiscountSet
      ? humanizedDisplayedDiscountWithSymbol
      : humanizedDiscountInclVatWithSymbol

    const discountName = discounts[0]?.name || ''

    return (
      <tr>
        <td>Heraf vises følgende rabat i tilbud</td>
        <td>{discountName}</td>
        <td colSpan={2}>{discount}</td>
      </tr>
    )
  }
}

export default OrderDisplayedWizardDiscountRow
