import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'

import { getCurrentUserRole } from '../../selectors/AuthSelectors'

import * as DialogActions from '../../actions/DialogActions'

import { IconButton } from '../Button'
import './style.styl'
import OrderWizardDiscountRow from '../OrderWizardDiscountRow'
import OrderDisplayedWizardDiscountRow from '../OrderDisplayedWizardDiscountRow'

class OrderDiscountsTable extends Component {
  static propTypes = {
    canEditAdditionalDiscount: PropTypes.bool.isRequired,
    openDialog: PropTypes.func.isRequired,
    order: PropTypes.shape({
      additionalDiscount: PropTypes.string,
      additionalDiscountOerer: PropTypes.number,
      totalPriceWithDiscountsOerer: PropTypes.number,
      energySubsidyOerer: PropTypes.number,
      discounts: PropTypes.arrayOf(
        PropTypes.shape({
          humanizedDiscountInclVatWithSymbol: PropTypes.string,
          coupon: PropTypes.shape({
            name: PropTypes.string,
          }),
        })
      ),
    }),
  }

  handleEditAdditionalDiscountClick = () => {
    const { order, openDialog } = this.props
    const additionalDiscount = order.additionalDiscountOerer / 100
    const priceWithAllDiscounts = order.totalPriceWithDiscountsOerer / 100
    const priceWithDiscounts = priceWithAllDiscounts + additionalDiscount
    const subsidy = order.energySubsidyOerer / 100

    openDialog('additionalDiscount', {
      orderId: order.id,
      totalPrice: priceWithDiscounts,
      discount: additionalDiscount,
      subsidy: (order.includeEnergySubsidy && subsidy) || 0,
    })
  }

  render() {
    const { order, canEditAdditionalDiscount } = this.props
    const {
      discounts = [],
      additionalDiscount,
      additionalDiscountOerer,
    } = order
    const hasAdditionalDiscount = additionalDiscountOerer !== 0

    const hideDiscounts =
      discounts.length === 0 &&
      !hasAdditionalDiscount &&
      !canEditAdditionalDiscount
    if (hideDiscounts) return null

    const otherDiscounts = discounts.filter(
      (d) => d.discountType !== 'wizard_discount'
    )

    const wizardDiscounts = discounts.filter(
      (d) => d.discountType === 'wizard_discount'
    )

    const hasWizardDiscounts = wizardDiscounts.length > 0

    return (
      <table className="order-discounts">
        <tbody>
          <tr key="header">
            <th>Rabatter</th>
            <th>{discounts.length !== 0 ? 'Vist navn' : ''}</th>
            <th className="text-right">Rabat (m. moms)</th>
          </tr>
          {otherDiscounts.map((discount) => (
            <tr key={discount.id}>
              <td>
                {discount.coupon?.name
                  ? discount.coupon.name
                  : 'Slettet rabattype'}
              </td>
              <td>{discount.name}</td>
              <td>-{discount.humanizedDiscountInclVatWithSymbol}</td>
            </tr>
          ))}
          {(hasAdditionalDiscount || canEditAdditionalDiscount) && (
            <tr>
              <td colSpan={2}>
                Prisjustering{' '}
                {canEditAdditionalDiscount && (
                  <IconButton
                    icon="compose"
                    onClick={this.handleEditAdditionalDiscountClick}
                  />
                )}
              </td>
              <td>{`${Math.round(additionalDiscount * -1.25)} kr.`}</td>
            </tr>
          )}
          {hasWizardDiscounts && <OrderWizardDiscountRow order={order} />}

          <OrderDisplayedWizardDiscountRow order={order} />
        </tbody>
      </table>
    )
  }
}

const mapStateToProps = (state) => ({
  canEditAdditionalDiscount: getCurrentUserRole(state) === 'super_admin',
})

export default connect(mapStateToProps, {
  ...DialogActions,
})(OrderDiscountsTable)
