import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'

import * as DialogActions from '../actions/DialogActions'
import * as DiscountActions from '../actions/DiscountActions'
import * as LineItemActions from '../actions/LineItemActions'
import * as OrderActions from '../actions/OrderActions'
import * as WizardBuildingActions from '../actions/WizardBuildingActions'

import { getOrder } from '../selectors/OrderSelectors'

import Breadcrumb, { BreadcrumbItem } from '../components/Breadcrumb'
import Button from '../components/Button'
import DraftDiscountsTable from '../components/DraftDiscountsTable'
import { Column, Margin, Row } from '../components/Grid'
import OrderPriceTable from '../components/OrderPriceTable'
import OrderWizardStepTitle from '../components/OrderWizard/StepTitle'
import Page, { PageContent, PageHeader } from '../components/Page'
import Spinner from '../components/Spinner'
import WizardBuildingTable from '../components/WizardBuildingTable'
import { formatDate } from '../utils/formatUtils'

class DraftContainer extends Component {
  static propTypes = {
    orderId: PropTypes.string.isRequired,
    order: PropTypes.object,
    customer: PropTypes.object,
    fetchOrder: PropTypes.func.isRequired,
    updateLineItem: PropTypes.func.isRequired,
    deleteLineItem: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
    proposeOrder: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,
    deleteWizardBuilding: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.secret = 'g9i398P6i6tW'
  }

  componentDidMount() {
    const { orderId } = this.props

    if (orderId) this.fetchOrder()
  }

  componentDidUpdate(oldProps) {
    if (!this.props.orderId) return

    if (oldProps.orderId !== this.props.orderId) {
      this.fetchOrder()
    }
  }

  fetchOrder() {
    const { fetchOrder, orderId } = this.props
    fetchOrder(orderId, {
      query: {
        include: [
          'discounts.coupon',
          'wizard_buildings',
          'wizard_buildings.building',
          'wizard_buildings.discounts',
          'wizard_buildings.wizard',
          'wizard_buildings.wizard_building_steps.line_items.building_property',
          'wizard_buildings.wizard_building_steps.step',
          'customer',
          'user',
        ],
      },
    })
  }

  handleProposeClick = () => {
    if (confirm('Er du sikker på at du vil færdiggøre tilbuddet?')) {
      const { proposeOrder, orderId, order } = this.props
      let apiUrl = `https://jydsktagteknik.dk/trustpilot-api/v1/rateAuto/${this.secret}/${orderId}/order/${order.user.id}/${order.user.role}/${order.user.name}/${order.customer.name}/${order.customer.email}/${order.customer.phone}`
      fetch(apiUrl)
      proposeOrder(orderId).then(() => this.props.push(`/orders/${orderId}`))
    }
  }

  handleNoteButtonClick = () => {
    const { orderId } = this.props
    this.props.openDialog('orderEditField', {
      orderId,
      title: 'Noter',
      field: 'note',
    })
  }

  handleWizardBuildingEdit = (wizardBuildingId) => {
    const { orderId } = this.props
    this.props.push(`/drafts/${orderId}/wizard-buildings/${wizardBuildingId}`)
  }

  handleWizardBuildingDelete = (wizardBuildingId) => {
    if (
      confirm(
        'Er du sikker på at du vil slette denne tilbudspakke fra kladden?'
      )
    ) {
      this.props
        .deleteWizardBuilding(wizardBuildingId)
        .then(() => this.fetchOrder())
    }
  }

  onUpdateDiscount = (discountId, newDiscountPercentage) => {
    const { updateDiscountPercentage } = this.props
    updateDiscountPercentage(discountId, {
      discountPercentage: newDiscountPercentage,
    }).then(() => this.fetchOrder())
  }

  renderNoteButton() {
    const { order } = this.props
    const buttonTitle = order.note ? 'Redigere noter' : 'Tilføj noter'
    return (
      <Margin vertical size={1}>
        <Button onClick={this.handleNoteButtonClick}>{buttonTitle}</Button>
      </Margin>
    )
  }

  render() {
    const { order } = this.props

    if (!order) return <Spinner center />
    const {
      order: { customer = {} },
    } = this.props

    return (
      <Page>
        <PageHeader>
          <Breadcrumb className="page__title">
            <BreadcrumbItem
              title="Opret tilbud"
              linkTo="/drafts/per-customer"
            />
            <BreadcrumbItem title={`Kladde #${order.id}`} />
          </Breadcrumb>
          <div className="page__actions">
            <Button onClick={this.handleProposeClick} active>
              Færdiggør
            </Button>
          </div>
        </PageHeader>
        <PageContent>
          <Margin all>
            <OrderWizardStepTitle>{order.name}</OrderWizardStepTitle>
          </Margin>
          <Margin all>
            <Row>
              <Column size={6}>
                <label className="order-header__label">Navn</label>
                {customer.name}
                <label className="order-header__label">Adresse</label>
                {customer.fullAddressStreet}, {customer.addressZipCode},{' '}
                {customer.addressCityName}
              </Column>
              <Column size={6}>
                <label className="order-header__label">Dato</label>
                {formatDate(customer.createdAt, 'j. F Y')}
                <label className="order-header__label">Total</label>
                {order.humanizedTotalPriceInclVatWithSymbol}
              </Column>
              <Column size={12}>
                <label className="order-header__label">Noter</label>
                {order.note}
                {this.renderNoteButton()}
              </Column>
            </Row>
          </Margin>
          <Margin all>
            <Link to={`/drafts/${order.id}/wizard-buildings/new`}>
              <Button>Tilføj tilbudspakke</Button>
            </Link>
          </Margin>

          <Margin all>
            {(order.wizardBuildings || []).map((wizardBuilding) => (
              <Margin key={wizardBuilding.id} bottom size={3}>
                <WizardBuildingTable
                  onEdit={this.handleWizardBuildingEdit}
                  onDelete={this.handleWizardBuildingDelete}
                  onUpdateDiscount={this.onUpdateDiscount}
                  user={order.user}
                  wizardBuilding={wizardBuilding}
                  allowLineItemEditing
                />
              </Margin>
            ))}
          </Margin>

          <Margin all>
            <DraftDiscountsTable order={order} />
          </Margin>

          <Margin all>
            <OrderPriceTable order={order} />
          </Margin>
        </PageContent>
      </Page>
    )
  }
}

const mapStateToProps = (state, props) => ({
  orderId: props.params.orderId,
  order: getOrder(state, props, [
    'discounts.coupon',
    'wizardBuildings.building',
    'wizardBuildings.discounts',
    'wizardBuildings.wizard',
    'wizardBuildings.wizardBuildingSteps.lineItems.buildingProperty',
    'wizardBuildings.wizardBuildingSteps.step',
    'customer',
    'user',
  ]),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      ...DialogActions,
      ...DiscountActions,
      ...OrderActions,
      ...LineItemActions,
      ...WizardBuildingActions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DraftContainer)
