import React, { Component } from 'react'
import PropTypes from 'react-proptypes'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import omit from 'lodash/omit'

import * as DialogActions from '../../actions/DialogActions'
import * as LineItemActions from '../../actions/LineItemActions'

import { getLineItem } from '../../selectors/LineItemSelectors'
import { isActioningEntityTypes } from '../../selectors/ApiSelectors'

import Dialog, {
  DialogHeader,
  DialogTitle,
  DialogCancelButton,
  DialogButton,
} from '../../components/Dialog'
import { Margin } from '../../components/Grid'
import Spinner from '../../components/Spinner'
import LineItemForm from '../../components/LineItemForm'

class LineItemDialog extends Component {
  static propTypes = {
    lineItemId: PropTypes.string.isRequired,
    lineItem: PropTypes.object,

    isFetching: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,

    closeDialog: PropTypes.func.isRequired,
    fetchLineItem: PropTypes.func.isRequired,
    updateLineItem: PropTypes.func.isRequired,

    fields: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { fetchLineItem, lineItemId } = this.props
    fetchLineItem(lineItemId, { query: { include: 'step_product' } })
  }

  handleSaveButtonClick = () => {
    this.formElement.getWrappedInstance().submit()
  }

  handleLineItemSubmit = (attributes) => {
    const { lineItem, updateLineItem, closeDialog } = this.props

    // Work around stupid API bug
    const fixedAttributes = omit(attributes, 'priceCorrectionInclVat')
    if (attributes.priceCorrectionInclVat === 0) {
      fixedAttributes.priceCorrection = ''
    } else {
      fixedAttributes.priceCorrectionInclVatOerer =
        attributes.priceCorrectionInclVat * 100
    }

    // These are included because line item's and the order's prices will possibly have changed
    // and we want that change to apply globally
    const query = {
      include: [
        'wizard_building_step.wizard_building.discount',
        'wizard_building_step.wizard_building.order',
      ],
    }
    return updateLineItem(lineItem.id, fixedAttributes, { query })
      .then(() => closeDialog())
      .catch(() => alert('Der opstod en uventet fejl, prøv venligst igen'))
  }

  renderForm() {
    const { lineItem, dirty, fields, handleSubmit, submitting } = this.props

    return (
      <LineItemForm
        ref={(element) => (this.formElement = element)}
        dirty={dirty}
        fields={fields}
        lineItem={lineItem}
        handleSubmit={handleSubmit(this.handleLineItemSubmit)}
        submitting={submitting}
      />
    )
  }

  render() {
    const { isFetching, isSaving, dirty, valid, lineItem, closeDialog } =
      this.props
    const isLoading = isFetching || !lineItem || !lineItem.stepProduct
    const canSubmit = dirty && valid

    return (
      <Dialog style={{ width: 'calc(100vw - 40px)', maxWidth: '900px' }}>
        <DialogHeader>
          <DialogCancelButton onClick={closeDialog}>Tilbage</DialogCancelButton>
          <DialogTitle>Justér ordrelinje</DialogTitle>
          <DialogButton
            disabled={isSaving || !canSubmit}
            onClick={this.handleSaveButtonClick}
          >
            Gem
          </DialogButton>
        </DialogHeader>
        {isLoading ? (
          <Margin all>
            <Spinner centerHorizontal />
          </Margin>
        ) : (
          this.renderForm()
        )}
      </Dialog>
    )
  }
}

const getInitialValues = (lineItem) =>
  lineItem
    ? {
        name: lineItem.name,
        description: lineItem.description,
        showPrice: lineItem.showPrice,
        addition: lineItem.addition,
        quantity: lineItem.quantity,
        quantityJustification: lineItem.quantityJustification || '',
        productUnitsPerQuantity: parseFloat(lineItem.productUnitsPerQuantity),
        priceCorrectionJustification:
          lineItem.priceCorrectionJustification || '',
        priceCorrectionInclVat: Math.round(lineItem.priceCorrectionInclVat),
      }
    : null

const mapStateToProps = (state, props) => {
  const params = props
  const lineItem = getLineItem(state, { params }, ['stepProduct'])

  return {
    lineItem,
    isFetching: isActioningEntityTypes(state, 'fetching', 'lineItems'),
    isSaving: isActioningEntityTypes(state, 'updating', 'lineItems'),
    initialValues: getInitialValues(lineItem),
  }
}

const validate = (values, { lineItem }) => {
  const errors = {}

  const {
    name,
    quantity,
    quantityJustification,
    productUnitsPerQuantity,
    priceCorrectionInclVat,
    priceCorrectionJustification,
  } = values

  if (name === '') errors.name = 'Påkrævet'
  if (quantity <= 0) errors.quantity = 'Skal være positiv'
  if (productUnitsPerQuantity <= 0)
    errors.productUnitsPerQuantity = 'Skal være positiv'
  if (isNaN(priceCorrectionInclVat)) {
    errors.priceCorrectionInclVat = 'Ugyldig indtastning'
  }

  const needsQuantityJustification = !!(
    lineItem.stepProduct && lineItem.stepProduct.result
  )
  const hasQuantityJustification = !!lineItem.quantityJustification
  const quantitiesChanged =
    quantity !== lineItem.quantity ||
    productUnitsPerQuantity !== parseFloat(lineItem.productUnitsPerQuantity) ||
    hasQuantityJustification
  if (
    needsQuantityJustification &&
    quantitiesChanged &&
    quantityJustification.trim() === ''
  ) {
    errors.quantityJustification =
      'Der skal angives en begrundelse for justering af antal og mængde'
  }

  const priceWasCorrected = priceCorrectionInclVat !== 0
  if (priceWasCorrected && priceCorrectionJustification.trim() === '') {
    errors.priceCorrectionJustification =
      'Der skal angives en begrundelse for prisjusteringen'
  }

  return errors
}

export default connect(mapStateToProps, {
  ...DialogActions,
  ...LineItemActions,
})(
  reduxForm({
    form: 'lineItem',
    fields: LineItemForm.fields,
    validate,
  })(LineItemDialog)
)
